import React from 'react';
import 'app.scss';
import { PanelRow } from 'components/panel/panel';
import BagIcon from 'images/icons/icons8/cute-color/icons8-shopping-bag-gray.svg';
import PhoneIcon from 'images/icons/icons8/cute-color/icons8-mobile-payment-gray.svg';
import CoinInHandIcon from 'images/icons/icons8/cute-color/icons8-receive-cash-gray.svg';
import { useAuth } from 'contexts/auth-context';
import { formatMoney } from 'utils/money';
import StatsTile, {
  StatsTileIcon,
  StatsTileContent,
  StatsTileLabel,
  StatsTileValue,
} from './stats-tile';

function StatsRow() {
  const { user } = useAuth();
  const {
    LifetimeDonations,
    LifetimeReuses,
    OutstandingDonations,
  } = user.UserStats;

  return (
    <PanelRow>
      <StatsTile>
        <StatsTileIcon icon={BagIcon} alt="Lifetime Reuses" />
        <StatsTileContent>
          <StatsTileValue>{LifetimeReuses}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nReuses'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile link="/donation-history" subLabel="Click Here for Details">
        <StatsTileIcon icon={PhoneIcon} alt="Lifetime Reuses" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(LifetimeDonations)}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nDonations'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile>
        <StatsTileIcon icon={CoinInHandIcon} alt="Lifetime Reuses" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(OutstandingDonations)}</StatsTileValue>
          <StatsTileLabel>{'Outstanding\nDonations'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
    </PanelRow>
  );
}

export default StatsRow;
