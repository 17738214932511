import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { css } from 'emotion';
import { useAuth } from 'contexts/auth-context';
import { parseDotNetDate } from 'utils/date';
import Panel from 'components/panel/panel';

const BadgeTooltipContent = ({ uuid, name, description, date }) => (
  <div
    className={css`
      margin: 0rem;
    `}
  >
    <h3
      className={css`
        color: #fff;
        text-align: center;
        font-size: 1.125rem;
        margin: 0.75rem 0 0.5rem;
      `}
    >
      {name}
    </h3>
    {description.split(/\\n/).map((text) => (
      <p
        className={css`
          color: #fff;
          margin: 0 1rem 0.75rem;
          text-align: center;
          font-size: 1rem;
        `}
        key={`${uuid}-${text}`}
      >
        {text}
      </p>
    ))}
    <footer
      className={css`
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        font-size: 1rem;
        margin: 0 -0.5rem;
        color: #ddd;
      `}
    >
      Earned {date}
    </footer>
  </div>
);

const Badge = ({ uuid, icon, name, description, date }) => {
  return (
    <Tippy
      content={
        <BadgeTooltipContent
          description={description}
          icon={icon}
          uuid={uuid}
          name={name}
          date={date}
        />
      }
      maxWidth={274}
      placement="bottom"
      hideOnClick={false}
    >
      <img
        src={icon}
        alt={name}
        className={css`
          width: 7rem;
          align-self: flex-start;
          @media (max-width: 650px) {
            width: 25%;
          }
          @media (max-width: 475px) {
            width: 33.33%;
          }
        `}
      />
    </Tippy>
  );
};

const BadgeListPanel = () => {
  const { user } = useAuth();
  const badgeList = user.BadgeList.map((badge) => {
    const BadgeDateEarned = parseDotNetDate(badge.BadgeDateEarned);
    const DateEarnedStr = BadgeDateEarned.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return {
      ...badge,
      BadgeDateEarned,
      DateEarnedStr,
    };
  });

  if (!badgeList?.length) {
    return null;
  }

  return (
    <Panel>
      <h2>Badges Earned</h2>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          margin: 0 -0.5rem;
        `}
      >
        {badgeList.map((badge) => (
          <Badge
            key={badge.BadgeUUID}
            description={badge.BadgeDescription}
            icon={badge.BadgeIconURL}
            uuid={badge.BadgeUUID}
            name={badge.BadgeName}
            date={badge.DateEarnedStr}
          />
        ))}
      </div>
    </Panel>
  );
};

export default BadgeListPanel;
