import React from 'react';
import Button from 'components/button/button';
import './last-non-profit.scss';

const LastNonProfit = ({ nonProfit, setSelectedCause = () => {} }) => {
  const handleDonateClick = () => {
    setSelectedCause({
      CauseID: nonProfit.CompanyID,
      CauseName: nonProfit.Name,
      CauseLocation: `${nonProfit.City}, ${nonProfit.State}`,
      CauseCategory: nonProfit.Category,
    });
  };

  return (
    <div className="last-non-profit-container">
      <div className="last-non-profit-content">
        <h2 className="last-non-profit-header">Your Last Non-Profit</h2>
        <div className="last-non-profit">{nonProfit.Name}</div>
      </div>
      <Button onClick={handleDonateClick}>Donate Again</Button>
    </div>
  );
};

export default LastNonProfit;
