import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User } from 'react-feather';
import HeaderLogo from 'images/header-logo.png';
import { useAuth } from 'contexts/auth-context';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/button/button';

import './header.scss';

function Header() {
  const { isLoggedIn, user } = useAuth();

  const { loginWithRedirect } = useAuth0();

  const location = useLocation();
  const isRedeemPage = location?.pathname.toLowerCase().includes('redeem');

  return (
    <header id="header" className={isRedeemPage ? 'redeem' : ''}>
      <section className="header-content">
        <section className="left-content">
          <Link to="/" className="home-button">
            <img src={HeaderLogo} alt="Reuse 2 Give" />
            <div className="header-text">Reuse 2 Give</div>
          </Link>
        </section>

        <section className="right-content">
          {isLoggedIn ? (
            <Link to="/contact-info" className="header-button">
              <User size={28} color="#333" />
              <div className="user-name">{user.FirstName}</div>
            </Link>
          ) : (
            <Button
              size="small"
              onClick={() => loginWithRedirect({ prompt: 'login' })}
            >
              Sign In
            </Button>
          )}
        </section>
      </section>
    </header>
  );
}

export default Header;
