import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import RangeInput from 'components/range-input/range-input';
import Panel from 'components/panel/panel';
import useWindowSize from 'hooks/use-window-size';
import { getCauseList } from 'utils/api';
import 'react-table/react-table.css';
import './cause-table.scss';

function CauseTable({
  accountUID,
  userLatitude,
  userLongitude,
  userZipCode,
  causeView,
  setSelectedCause,
}) {
  const { screenSize } = useWindowSize();

  const [isLoading, setLoading] = useState(true);
  const [causeList, setCauseList] = useState();
  const [numPages, setNumPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [sorted, setSorted] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [causeDistance, setCauseDistance] = useState(10);
  const isDistanceDisabled = !!searchTerm;

  const getColumns = () => {
    switch (screenSize) {
      case 'large':
        return [
          {
            Header: 'Name',
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Category',
            accessor: 'CauseCategory',
            maxWidth: 150,
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
            maxWidth: 150,
          },
          {
            Header: 'Distance',
            accessor: 'CauseDistance',
            maxWidth: 100,
            Cell: (row) => (
              <div style={{ textAlign: 'right' }}>{row.value} miles</div>
            ),
          },
        ];
      case 'medium':
        return [
          {
            Header: 'Non-Profit',
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
          },
        ];
      default:
        return [
          {
            Header: 'Non-Profit',
            id: 'CauseName',
            accessor: (d) => [d.CauseName, d.CauseLocation, d.CauseDistance],
            Cell: (row) => (
              <div>
                <div>{row.value[0]}</div>
                <div>{`${row.value[1]} - ${row.value[2]} miles away`}</div>
              </div>
            ),
          },
        ];
    }
  };

  const pageSize = screenSize === 'small' ? 10 : 15;

  const handleCauseClick = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          setSelectedCause(rowInfo.original);
          console.log('CAUSE CLICKED', rowInfo.original);
        },
        style: {
          cursor: 'pointer',
        },
      };
    }
    return {};
  };

  useEffect(() => {
    let isCurrent = true;

    const getTableData = async () => {
      setLoading(true);
      let query = `select * from (select *,[dbo].[UDF_Distance](latitude,longitude,${userLatitude},${userLongitude}) as causedistance from ${causeView}) as a where 1=1`;
      if (searchTerm !== '') {
        query += ` and (causename like '%${searchTerm.replace(
          "'",
          "''"
        )}%' or causecity like '${searchTerm.replace("'", "''")}%')`;
      } else {
        query += ` and latitude not in (0,1) and causeDistance <= ${causeDistance}`;
      }

      const params = {
        userUID: '',
        accountUID,
        programUID: '',
        sqlStatement: query,
        lat: userLatitude,
        lng: userLongitude,
        distance: causeDistance,
        filter: '',
        pageNum,
        limit: pageSize,
        start: pageNum * pageSize,
      };

      if (sorted && sorted.length) {
        const arr = [];
        arr.push({
          property: sorted[0].id,
          direction: sorted[0].desc ? 'DESC' : 'ASC',
        });
        params.sort = `[{"property": "${
          sorted[0].id === 'CauseLocation' ? 'CauseCity' : sorted[0].id
        }", "direction": ${sorted[0].desc ? '"DESC"' : '"ASC"'}}]`;
      }

      const causeResp = await getCauseList(params);

      if (isCurrent) {
        const causes = causeResp.causes.map((cause) => ({
          ...cause,
          CauseLocation: `${cause.CauseCity}, ${cause.CauseState}`,
          CauseDistance: cause.CauseDistance.toFixed(1),
        }));

        setCauseList(causes);
        setNumPages(Math.ceil(causeResp.totalCount / pageSize));
        setLoading(false);
      }
    };

    console.log(accountUID, userLatitude, userLongitude);
    if (accountUID && userLatitude && userLongitude) {
      getTableData();
    }

    return () => {
      isCurrent = false;
    };
  }, [
    accountUID,
    causeDistance,
    causeView,
    pageNum,
    pageSize,
    searchTerm,
    sorted,
    userLatitude,
    userLongitude,
  ]);

  const fetchData = (state) => {
    console.log('fetch data', state);
    setPageNum(state.page);
    setSorted(state.sorted);
  };

  return (
    <Panel>
      <h2>Select a New Non-Profit</h2>
      <div className="table-controls">
        <div className="form-group">
          <label htmlFor="causeSearchInput">Search Non-Profits</label>
          <input
            type="text"
            className="form-control"
            id="causeSearchInput"
            aria-describedby="causeSearch"
            placeholder="Enter Non-Profit Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="form-group">
          <RangeInput
            label={`Within ${causeDistance} miles of ${userZipCode}`}
            min={10}
            max={200}
            value={causeDistance}
            onChange={(newDistance) => setCauseDistance(newDistance)}
            step={10}
            disabled={isDistanceDisabled}
            editable={false}
            showValue={false}
            className="form-control"
          />
        </div>
      </div>
      <ReactTable
        columns={getColumns()}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={causeList}
        showPaginationTop
        showPaginationBottom={false}
        pages={numPages} // Display the total number of pages
        loading={isLoading} // Display the loading overlay when we need it
        onFetchData={fetchData}
        pageSize={pageSize}
        showPageSizeOptions={false}
        showPageJump={false}
        className="-striped -highlight"
        resizable={false}
        page={pageNum}
        getTrProps={handleCauseClick}
      />
    </Panel>
  );
}

export default CauseTable;
