import React, { useState, useEffect, useCallback } from 'react';
import { CheckCircle } from 'react-feather';
import Button from 'components/button/button';
import Loader from 'components/loader/loader';
import { submitR2gDonation } from 'utils/api';
import { useAuth } from 'contexts/auth-context';
import { useDonations } from 'contexts/donation-context';
import './confirmation-modal.scss';

const modalStates = {
  WAITING: 'WAITING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  DONE: 'DONE',
  FAILURE: 'FAILURE',
};

const ConfirmationModalSponsor = ({ sponsor }) => (
  <div className="confirmation-modal-sponsor">
    <div>
      <img
        className="confirmation-modal-sponsor-logo"
        src={sponsor.AccountLogo.Small}
        alt={sponsor.AccountName}
      />
    </div>
    <p className="confirmation-modal-sponsor-message">
      These donations are proudly provided by {sponsor.AccountName} in support
      of local non-profits.
    </p>
  </div>
);

export default function ConfirmationModal({
  sponsor = {},
  hidden = true,
  selectedCause,
  closeModal,
  amount = '$0.00',
  donationObj = {},
}) {
  // Local selected cause to prevent flash during Cancel animation
  const [localSelectedCause, setLocalSelectedCause] = useState(selectedCause);
  useEffect(() => {
    let isCurrent = true;

    if (selectedCause) {
      setLocalSelectedCause(selectedCause);
    } else {
      setTimeout(() => {
        if (isCurrent) {
          setLocalSelectedCause(selectedCause);
        }
      }, 200);
    }

    return () => {
      isCurrent = false;
    };
  }, [selectedCause]);

  const { refreshDonations } = useDonations();

  const { user, updateUserStats } = useAuth();
  const [modalState, setModalState] = useState(modalStates.WAITING);

  const submitDonation = useCallback(async () => {
    setModalState(modalStates.LOADING);
    // TODO: Add error handling
    await submitR2gDonation({
      UserUUID: user.UUID,
      Donation: {
        ...donationObj,
        CauseID: selectedCause.CauseID,
      },
      TimezoneOffset: new Date().getTimezoneOffset(),
    });
    window.gtag('event', 'donate', { donationObj });
    updateUserStats();
    setModalState(modalStates.SUCCESS);
  }, [donationObj, user, selectedCause, updateUserStats]);

  const getModalContent = () => {
    switch (modalState) {
      case modalStates.WAITING:
        return (
          <>
            <div className="confirmation-modal-content">
              <h4 className="confirmation-modal-header">
                Are you sure you would like to direct your{' '}
                <span className="confirmation-modal-value">{amount}</span>{' '}
                donation to:
              </h4>
              {localSelectedCause && (
                <>
                  <h4 className="confirmation-modal-header confirmation-modal-cause">
                    {localSelectedCause.CauseName}
                  </h4>
                  <p className="confirmation-modal-description">
                    {localSelectedCause.CauseLocation}
                  </p>
                  <p className="confirmation-modal-description">
                    Category: {localSelectedCause.CauseCategory}
                  </p>
                </>
              )}
            </div>
            <ConfirmationModalSponsor sponsor={sponsor} />
            <div className="confirmation-modal-button-section">
              <Button onClick={closeModal}>Cancel</Button>
              <Button onClick={submitDonation}>Submit</Button>
            </div>
          </>
        );

      case modalStates.LOADING:
        return (
          <div className="confirmation-modal-loader-container">
            <Loader />
          </div>
        );

      case modalStates.SUCCESS:
        return (
          <>
            <div className="confirmation-modal-content">
              <h2 className="confirmation-modal-title">Success!</h2>
              <h4 className="confirmation-modal-header">
                You have successfully donated{' '}
                <span className="confirmation-modal-value">{amount}</span> to{' '}
                <span className="confirmation-modal-inline-cause">
                  {localSelectedCause?.CauseName}
                </span>
              </h4>
            </div>
            <ConfirmationModalSponsor sponsor={sponsor} />
            <div className="confirmation-modal-button-section">
              <Button onClick={refreshDonations}>Done</Button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`confirmation-modal-page ${hidden && 'hidden'}`}>
      <div className="confirmation-modal-window">
        <div className="confirmation-modal-icon-header">
          <CheckCircle color="#fff" size={36} />
        </div>
        {getModalContent()}
      </div>
    </div>
  );
}
