import React from 'react';
import Page from 'components/page/page';
import DonationList from 'components/donation-list/donation-list';
import StatsRow from 'components/stats-row/stats-row';
import BadgeListPanel from 'components/badge-list-panel/badge-list-panel';

function HomePage() {
  return (
    <Page>
      <DonationList />
      <StatsRow />
      <BadgeListPanel />
    </Page>
  );
}

export default HomePage;
