import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import Panel from 'components/panel/panel';
import { useDonations } from 'contexts/donation-context';
import { formatMoney } from 'utils/money';
import Loader from 'components/loader/loader';
import './donation-list.scss';

const getDonationKey = (donation) =>
  `${donation.StoreAccount.AccountID}-${donation.SponsorAccount.AccountID}`;

const getDonationPath = (donation) =>
  `/redeem/${donation.StoreAccount.AccountID}-${donation.SponsorAccount.AccountID}/`;

const DonationItem = ({ donation }) => {
  return (
    <Link
      className={css`
        display: block;
        margin-bottom: 1.5rem;
        text-decoration: none;
      `}
      to={getDonationPath(donation)}
    >
      <div className="donation-item-body">
        <div className="donation-item-image-container">
          <div
            className="donation-item-image"
            style={{
              backgroundImage: `url(${donation.SponsorAccount.AccountLogo.Small})`,
            }}
          />
          <div
            className="donation-item-image"
            style={{
              backgroundImage: `url(${donation.StoreAccount.AccountLogo.Small})`,
            }}
          />
        </div>

        <div className="donation-item-text-container">
          <h2 className="donation-item-header">
            {donation.SponsorAccount.AccountName} &{' '}
            {donation.StoreAccount.AccountName}
          </h2>
          <div className="donation-item-amount-container">
            <div className="donation-item-amount-tile">
              <div className="donation-item-amount-value">
                {donation.TotalReuses}
              </div>
              <div className="donation-item-amount-label">Bags Used</div>
            </div>
            <div className="donation-item-amount-tile">
              <div className="donation-item-amount-value">
                {formatMoney(donation.TotalDonations)}
              </div>
              <div className="donation-item-amount-label">
                Outstanding Donations
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

function RedeemList() {
  console.log('RedeemList');
  const {
    donationList,
    donationLoadingState,
    donationLoadingStates,
  } = useDonations();

  const getDonationListBody = () => {
    if (donationLoadingState !== donationLoadingStates.LOADED) {
      return (
        <div className="donation-list-loading-container">
          <Loader />
        </div>
      );
    }
    if (donationList && donationList.length > 0) {
      return donationList.map((donation) => (
        <DonationItem donation={donation} key={getDonationKey(donation)} />
      ));
    }

    return (
      <>
        <p>No donations available to redeem</p>
        <p>Submit a reuse on the Reuse 2 Give app to receive donations!</p>
      </>
    );
  };

  return (
    <Panel>
      <h1 className="page-title">Your Available Donations</h1>
      {getDonationListBody()}
    </Panel>
  );
}

export default RedeemList;
