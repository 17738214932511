import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import useLocalStorage from 'hooks/use-local-storage';
import {
  getR2gUserFromAuthID,
  getR2gUserFromUUID,
  getR2gStats,
} from 'utils/api';
import { FullScreenLoader } from 'components/loader/loader';

const loadStates = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState();
  const [user, setUser] = useState({});
  const [userID, setUserID] = useLocalStorage('user_id', '');

  const loadRef = useRef(loadStates.NOT_LOADED);
  const [loadState, setLoadState] = useState(loadStates.LOADING);
  const updateLoadState = useCallback((newState) => {
    loadRef.current = newState;
    setLoadState(newState);
  }, []);

  const auth0User = useAuth0().user;

  useEffect(() => {
    if (loadRef.current === loadStates.NOT_LOADED) {
      updateLoadState(loadStates.LOADING);

      const attemptInitialLogin = async () => {
        console.log('Attempt login with UUID');
        const searchVals = qs.parse(window.location.search.slice(1));
        const userIdForLogin = searchVals.user_uuid || userID || '';
        console.log(userIdForLogin);
        if (userIdForLogin) {
          const userResponse = await getR2gUserFromUUID(userIdForLogin);
          if (userResponse.Success) {
            updateLoadState(loadStates.LOADED);
            setUserID(userResponse.UUID);
            window.gtag('set', { userId: userResponse.UUID });
            window.gtag('event', 'login', { method: 'userUuid' });

            setUser(userResponse);
            setLoggedIn(true);
            return;
          }
        }

        updateLoadState(loadStates.NOT_LOADED);
        setLoggedIn(false);
        setUserID('');
      };

      attemptInitialLogin();
    }
  }, [userID, setUserID, updateLoadState]);

  // Load from AuthID
  useEffect(() => {
    if (loadRef.current === loadStates.NOT_LOADED && auth0User?.sub) {
      updateLoadState(loadStates.LOADING);
      const attemptLoginWithUserID = async () => {
        const userResponse = await getR2gUserFromAuthID(auth0User.sub);
        if (userResponse.Success) {
          updateLoadState(loadStates.LOADED);
          setUserID(userResponse.UUID);
          window.gtag('set', { userId: userResponse.UUID });
          window.gtag('event', 'login', { method: 'auth0' });
          setUser(userResponse);
          setLoggedIn(true);
        }
      };

      attemptLoginWithUserID();
    }
  }, [auth0User, setUserID, updateLoadState]);

  const updateUserStats = async () => {
    if (user?.UserID) {
      const UserStats = await getR2gStats(user.UserID);

      setUser((prevUser) => ({
        ...prevUser,
        UserStats,
      }));
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, updateUserStats }}>
      {isLoggedIn === undefined || loadState === loadStates.LOADING ? (
        <FullScreenLoader />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authValue = useContext(AuthContext);
  return authValue;
};

export { AuthContext as default, AuthProvider, useAuth };
