import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Page from 'components/page/page';
import Panel from 'components/panel/panel';
import Button from 'components/button/button';
import { css } from 'emotion';
import IphoneMockupImage from 'images/iphone-mockup.png';
import AppStoreBadge from 'images/app-store-badge.svg';
import GooglePlayBadge from 'images/google-play-badge.svg';

function WelcomePage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Page>
      <Panel>
        <h1>Welcome to Reuse 2 Give</h1>
        <div
          className={css`
            display: flex;
            @media (max-width: 650px) {
              flex-direction: column;
            }
          `}
        >
          <div
            className={css`
              flex: 1;
              padding: 1rem 0;
              @media (max-width: 650px) {
                display: flex;
                justify-content: center;
              }
            `}
          >
            <img
              src={IphoneMockupImage}
              alt="Reuse 2 Give App"
              className={css`
                width: 100%;
                height: auto;
                @media (max-width: 650px) {
                  width: auto;
                  height: 15rem;
                }
              `}
            />
          </div>
          <div
            className={css`
              flex: 2;
              margin-left: 2rem;

              @media (max-width: 650px) {
                margin-left: 0;
              }
            `}
          >
            <p>
              The Reuse 2 Give Program allows you to give back to a local
              non-profit with every reuse of a reusable bag. It all begins by
              downloading the Reuse 2 Give App and purchasing at least one{' '}
              <a href="https://www.reuse2give.com/purchase-r2g-tags/">
                Reuse 2 Give Tag
              </a>
              . Once you’ve received your tag, register it in the App, attach it
              to the reusable bag of your choice, and start giving back with
              every reuse!
            </p>
            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              <a href="https://apps.apple.com/us/app/reuse-2-give/id1534024782">
                <img
                  src={AppStoreBadge}
                  alt="Apple App Store"
                  className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.reuse2give">
                <img
                  src={GooglePlayBadge}
                  alt="Google Play Store"
                  className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
                />
              </a>
            </div>
            <p>
              If you have already registered, Sign In to your account to direct
              your donations and see your community impact!
            </p>
            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Button
                size="large"
                onClick={() => loginWithRedirect({ prompt: 'login' })}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </Page>
  );
}

export default WelcomePage;
