import React, { useEffect, useState } from 'react';
import Panel from 'components/panel/panel';
import { getR2gDonationHistory } from 'utils/api';
import { parseDotNetDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { useAuth } from 'contexts/auth-context';
import Loader from 'components/loader/loader';
import { css } from 'emotion';
import { useTheme } from 'emotion-theming';

const DonationHistoryColumn = ({
  header = false,
  right = false,
  flex = 1,
  children,
}) => (
  <article
    className={css`
      font-weight: ${header ? 'bold' : 'regular'};
      text-align: ${right ? 'right' : 'left'};
      flex: ${flex};
    `}
  >
    {children}
  </article>
);

const DonationHistoryListItem = ({ children }) => {
  const { colors } = useTheme();
  return (
    <li
      className={css`
        border-bottom: 1px solid ${colors.whiteGray};
        display: flex;
        padding: 0.5rem 0;
      `}
    >
      {children}
    </li>
  );
};

const DonationHistoryList = ({ children }) => (
  <ul
    className={css`
      list-style: none;
      padding: 0;
    `}
  >
    {children}
  </ul>
);

const LoaderContainer = ({ children }) => (
  <div
    className={css`
      height: 16rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    {children}
  </div>
);

function DonationHistory() {
  const UserUUID = useAuth()?.user?.UUID;

  const [donationHistoryList, setDonationHistoryList] = useState([]);
  const [isDonationHistoryLoading, setDonationHistoryLoading] = useState(true);

  useEffect(() => {
    let isCurrent = true;

    if (UserUUID) {
      console.log('UserUUID in Donation History:', UserUUID);
      getR2gDonationHistory({ UserUUID }).then((resp) => {
        if (isCurrent) {
          console.log('DONATION HISTORY', resp);
          setDonationHistoryList(resp.DonationList);
          setDonationHistoryLoading(false);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [UserUUID]);

  return (
    <Panel>
      <h1>Donation History</h1>
      {isDonationHistoryLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <DonationHistoryList>
          <DonationHistoryListItem>
            <DonationHistoryColumn header flex={2}>
              Non-Profit
            </DonationHistoryColumn>
            <DonationHistoryColumn header right>
              Amount
            </DonationHistoryColumn>
            <DonationHistoryColumn header right>
              Date
            </DonationHistoryColumn>
          </DonationHistoryListItem>
          {donationHistoryList.map((donation) => (
            <DonationHistoryListItem key={donation.DateRedeemed}>
              <DonationHistoryColumn flex={2}>
                {donation.LastCause.Name}
              </DonationHistoryColumn>
              <DonationHistoryColumn right>
                {formatMoney(donation.TotalDonations)}
              </DonationHistoryColumn>
              <DonationHistoryColumn right>
                {parseDotNetDate(donation.DateRedeemed).toLocaleDateString()}
              </DonationHistoryColumn>
            </DonationHistoryListItem>
          ))}
        </DonationHistoryList>
      )}
    </Panel>
  );
}

export default DonationHistory;
