/* eslint-disable import/prefer-default-export */
import qs from 'qs';

function fetchWithTimeout(url, options, timeout, onTimeout) {
  const timer = new Promise((resolve) => {
    setTimeout(resolve, timeout, {
      timeout: true,
    });
  });
  return Promise.race([fetch(url, options), timer]).then((response) => {
    if (response.timeout) {
      onTimeout();
    }
    return response;
  });
}

export async function getCauseList(params) {
  const response = await fetchWithTimeout(
    `${
      process.env.REACT_APP_CDDS_API_ENDPOINT
    }/api/Cause/CDDSCauses?${qs.stringify(params)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    10000,
    () => {
      throw new Error('Session Timed out');
    }
  );

  if (!response.ok || response.status !== 200) {
    throw new Error('There was an error processing your session request');
  }

  const data = await response.json();
  console.log('Get R2g Web Session', data);
  return data;
}

export const getR2gUserFromAuthID = async (AuthID) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetR2gUserFromAuthID`,
    {
      body: JSON.stringify({
        AuthID,
        TimezoneOffset: new Date().getTimezoneOffset(),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('User Response Data', data);
    return data;
  }

  return { Success: false };
};

export const getR2gUserFromUUID = async (UUID) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetR2gUserFromUUID`,
    {
      body: JSON.stringify({
        UUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('User Response Data', data);
    return data;
  }

  return { Success: false };
};

export const getR2gAllOutstandingDonations = async ({
  UserUUID,
  TimezoneOffset,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetR2gAllOutstandingDonations`,
    {
      body: JSON.stringify({
        UserUUID,
        TimezoneOffset,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('Outstanding Donations Data', data);
    return data;
  }

  return { Success: false };
};

export const getR2gDonationHistory = async ({ UserUUID }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetR2gDonationHistory`,
    {
      body: JSON.stringify({
        UserUUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('Donation History', data);
    return data;
  }

  return { Success: false };
};

export const submitR2gDonation = async ({
  UserUUID,
  Donation,
  TimezoneOffset,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/SubmitR2gDonation`,
    {
      body: JSON.stringify({
        UserUUID,
        Donation,
        TimezoneOffset,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('Donation Submition Data', data);
    return data;
  }

  return { Success: false };
};

export const getR2gStats = async (UserID) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetR2gStats`,
    {
      body: JSON.stringify({
        UserID,
        TimezoneOffset: new Date().getTimezoneOffset(),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('User Response Data', data);
    return data;
  }

  return { Success: false };
};

export const submitR2gPageView = async ({
  UserID,
  AccountID,
  SponsorID,
  Pathname,
  Ref,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/SubmitR2gPageView`,
    {
      body: JSON.stringify({
        PageView: {
          UserID,
          AccountID,
          SponsorID,
          Pathname,
          Ref,
          TimezoneOffset: new Date().getTimezoneOffset(),
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  }

  return { Success: false };
};
