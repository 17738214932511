import React from 'react';
import { css, cx } from 'emotion';
import { useTheme } from 'emotion-theming';

function Button({
  children,
  onClick = () => {},
  type = 'primary',
  size = 'medium',
}) {
  const { colors, general } = useTheme();

  const getFontSize = () => {
    switch (size) {
      case 'large':
        return '1.5rem';

      case 'small':
        return '1rem';

      case 'medium':
      default:
        return '1.25rem';
    }
  };

  const baseStyles = css`
    color: ${colors.offWhite};
    text-decoration: none;
    padding: 0.5em 0.875em;
    font-size: ${getFontSize()};
    line-height: 1;
    border-radius: 0.25em;
    border: 0;
    display: inline-flex;
    align-items: center;
    box-shadow: ${general.buttonBoxShadow};
    justify-content: center;
    cursor: pointer;
    transition: 100ms all;
    &:disabled,
    &:disabled:hover {
      background-color: darkgrey;
      cursor: default;
    }
  `;

  const primaryStyles = css`
    background: ${colors.lightBlue};
    &:hover {
      background-color: ${colors.lightBlueShade};
    }
  `;

  const secondaryStyles = css`
    background: transparent;
    border: 2px solid $off-white;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.66;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  `;

  return (
    <button
      className={cx(
        { [baseStyles]: true },
        { [primaryStyles]: type === 'primary' },
        { [secondaryStyles]: type === 'secondary' }
      )}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
