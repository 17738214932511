import React from 'react';
import HeaderLogo from 'images/header-logo.png';
import './footer.scss';

const FooterLink = ({ children, link }) => (
  <a
    className="footer-resources-link"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

function Footer() {
  return (
    <footer id="app-footer">
      <article className="footer-content">
        <section className="footer-content-left">
          <div className="footer-resources-header">Resources</div>
          <FooterLink link="https://www.reuse2give.com/">
            Program Website
          </FooterLink>
          <FooterLink link="https://www.reuse2give.com/faqs/">FAQs</FooterLink>
          <FooterLink link="https://www.reuse2give.com/download-the-app/">
            Download the App
          </FooterLink>
          <FooterLink link="https://www.reuse2give.com/privacy-policy/">
            Privacy Policy
          </FooterLink>
        </section>
        <section className="footer-content-left">
          <div className="footer-resources-header">Contact</div>
          <FooterLink link="mailto:helpdesk@reuse2give.com">
            helpdesk@reuse2give.com
          </FooterLink>
          <FooterLink link="tel:+16032223333">(603) 222-3333</FooterLink>
        </section>
        <section className="footer-content-right">
          <article className="footer-copyright-container">
            <img className="footer-logo" src={HeaderLogo} alt="Reuse 2 Give" />
            <span className="footer-copyright">© Reuse 2 Give 2020</span>
          </article>
        </section>
      </article>
    </footer>
  );
}

export default Footer;
