import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import AuthContext from 'contexts/auth-context';
import { getR2gAllOutstandingDonations } from 'utils/api';
// import { FullScreenLoader } from 'components/loader/loader';

const loadStates = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const loadDonations = async (UserUUID) => {
  const TimezoneOffset = new Date().getTimezoneOffset();
  console.log('TIMEZONE OFFSET', TimezoneOffset);
  console.time('oustandingDonations');
  const donationResp = await getR2gAllOutstandingDonations({
    UserUUID,
    TimezoneOffset,
  });
  console.timeEnd('oustandingDonations');
  return donationResp;
};

const DonationContext = createContext();

const DonationProvider = ({ children }) => {
  console.log('DONATION PROVIDER');
  const donationLoadingRef = useRef(loadStates.NOT_LOADED);
  const [donationLoadingState, setDonationLoadingState] = useState(
    donationLoadingRef.current
  );
  const setDonationLoading = (newVal) => {
    donationLoadingRef.current = newVal;
    setDonationLoadingState(newVal);
  };

  const { user } = useContext(AuthContext);
  const UserUUID = user?.UUID;
  const [donationList, setDonationList] = useState([]);

  const refreshDonations = () => {
    setDonationLoading(loadStates.LOADING);
    loadDonations(UserUUID).then((donationResp) => {
      setDonationLoading(loadStates.LOADED);
      if (donationResp.Success) {
        setDonationList(donationResp.DonationList);
      }
    });
  };

  useEffect(() => {
    let isCurrent = true;

    if (UserUUID && donationLoadingRef.current === loadStates.NOT_LOADED) {
      console.log('FIRE OUTSTANDING DONATION REQUEST');
      setDonationLoading(loadStates.LOADING);
      loadDonations(UserUUID).then((donationResp) => {
        if (donationResp.Success && isCurrent) {
          setDonationList(donationResp.DonationList);
          setDonationLoading(loadStates.LOADED);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [UserUUID]);

  return (
    <DonationContext.Provider
      value={{
        donationList,
        refreshDonations,
        donationLoadingState,
        donationLoadingStates: loadStates,
      }}
    >
      {children}
    </DonationContext.Provider>
  );
};

const useDonations = () => {
  const donationContext = useContext(DonationContext);
  return donationContext;
};

export { DonationContext as default, DonationProvider, useDonations };
